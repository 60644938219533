import React from "react";
import PathConstants from "./pathConstants";

const Home = React.lazy(() => import("../pages/Home"));
const Services = React.lazy(() => import("../pages/Services"));

export const routes = [
  { path: PathConstants.HOME, element: <Home /> },
  { path: PathConstants.SERVICES, element: <Services /> },
];

export default routes;
