import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { ScrollToTopOnPageChange } from "../scroll/Scroll";
import { Helmet } from "react-helmet";

export default function Layout() {
  return (
    <>
      <Helmet>
        <title>YXL Lojistik </title>
        <meta name="description" content="YXL Lojistik" />
        <meta
          name="keywords"
          content="YXL Lojistik, Lojistik, YXL, yxl lojistik, yxl"
        />
      </Helmet>
      <main>
        <Suspense>
          <ScrollToTopOnPageChange />
          <Outlet />
        </Suspense>
      </main>
    </>
  );
}
